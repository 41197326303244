// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-nehalem-fork-src-pages-404-tsx": () => import("./../../../node_modules/nehalem-fork/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-nehalem-fork-src-pages-404-tsx" */),
  "component---node-modules-nehalem-fork-src-pages-archive-tsx": () => import("./../../../node_modules/nehalem-fork/src/pages/archive.tsx" /* webpackChunkName: "component---node-modules-nehalem-fork-src-pages-archive-tsx" */),
  "component---node-modules-nehalem-fork-src-pages-tags-tsx": () => import("./../../../node_modules/nehalem-fork/src/pages/tags.tsx" /* webpackChunkName: "component---node-modules-nehalem-fork-src-pages-tags-tsx" */),
  "component---node-modules-nehalem-fork-src-templates-post-tsx": () => import("./../../../node_modules/nehalem-fork/src/templates/post.tsx" /* webpackChunkName: "component---node-modules-nehalem-fork-src-templates-post-tsx" */),
  "component---node-modules-nehalem-fork-src-templates-posts-tsx": () => import("./../../../node_modules/nehalem-fork/src/templates/posts.tsx" /* webpackChunkName: "component---node-modules-nehalem-fork-src-templates-posts-tsx" */),
  "component---node-modules-nehalem-fork-src-templates-tag-tsx": () => import("./../../../node_modules/nehalem-fork/src/templates/tag.tsx" /* webpackChunkName: "component---node-modules-nehalem-fork-src-templates-tag-tsx" */)
}

