module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flutter Institute - Tutorials and ramblings about flutter.dev","short_name":"Flutter Institute","start_url":"/","background_color":"#a4cbb8","theme_color":"#a4cbb8","display":"minimal-ui","icon":"/usr/src/app/www/flutter-institute/content/assets/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3b285bcfd18dbaacdf25ac9cc175d0c1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"content","store":true},{"name":"tags","store":true},{"name":"excerpt","store":true},{"name":"path","store":true}],"resolvers":{"MarkdownRemark":{}}},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    }]
